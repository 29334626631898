
import Vue from "vue";

import { DialogI } from "@/types/dialog";

export default Vue.extend({
  name: "DialogsContainer",

  components: {
    CustomPopup: () => import("@/components/dialog/CustomPopup.vue"),
    CreateCourseDialog: () => import("@/components/dialog/CreateCourseDialog.vue"),
    AttachExistingLessonDialog: () => import("@/components/dialog/AttachExistingLessonDialog.vue"),
    AttachExistingExerciseDialog: () => import("@/components/dialog/AttachExistingExerciseDialog.vue"),
    CreateLessonDialog: () => import("@/components/dialog/CreateLessonDialog.vue"),
    DeleteConfirmationDialog: () => import("@/components/dialog/DeleteConfirmationDialog.vue"),
    DisplaySingleHintDialog: () => import("@/components/dialog/DisplaySingleHintDialog.vue"),
    DisplayHintsListDialog: () => import("@/components/dialog/DisplayHintsListDialog.vue"),
    LessonPreviewFinishedDialog: () => import("@/components/dialog/LessonPreviewFinishedDialog.vue"),
    LessonFinishedDialog: () => import("@/components/dialog/LessonFinishedDialog.vue"),
    RepetitionSessionFinishedDialog: () => import("@/components/dialog/RepetitionSessionFinishedDialog.vue"),
    CourseFinishedDialog: () => import("@/components/dialog/CourseFinishedDialog.vue"),
    LessonTimePassedDialog: () => import("@/components/dialog/LessonTimePassedDialog.vue"),
    LessonGlossaryDialog: () => import("@/components/dialog/LessonGlossaryDialog.vue"),
    WelcomeDialog: () => import("@/components/dialog/WelcomeDialog.vue"),
    UserProfileActionsDialog: () => import("./UserProfileActionsDialog.vue"),
    UserProfileInvoiceDialog: () => import("./UserProfileInvoiceDialog.vue"),
    UserProfileDeleteProgressDialog: () => import("./UserProfileDeleteProgressDialog.vue"),
    UserProfileDeleteProgressConfirmDialog: () => import("./UserProfileDeleteProgressConfirmDialog.vue"),
    ReportBugDialog: () => import("@/components/dialog/ReportBugDialog.vue"),
    AddNote: () => import("@/components/dialog/AddNote.vue"),
    ListeningAndWritingActivitiesDialog: () => import("@/components/dialog/ListeningAndWritingActivitiesDialog.vue"),
    MediaAddFileDialog: () => import("@/components/dialog/MediaAddFileDialog.vue"),
    MediaRenameFileDialog: () => import("@/components/dialog/MediaRenameFileDialog.vue"),
    GiftCardAddDialog: () => import("@/components/dialog/GiftCardAddDialog.vue"),
    InviteUsersToCompany: () => import("@/components/dialog/InviteUsersToCompany.vue"),
    AddFirstAndLastNameToCompanyUser: () => import("@/components/dialog/AddFirstAndLastNameToCompanyUser.vue"),
  },

  computed: {
    dialogs(): DialogI[] {
      return this.$store.getters["dialog/getList"];
    },
  },
});
