
import moment from "moment";
import Vue, { PropType } from "vue";
import { RawLocation } from "vue-router";
import Visibility from "visibilityjs";

import { DialogCloseType } from "@/types/dialog";
import HeaderUserMenu from "@/components/base/HeaderUserMenu.vue";
import Btn from "@/components/v2/base/Btn.vue";
import { LearningMode, UserPreferences } from "@prestonly/preston-common";
import { getLearningModeFromRoute } from "@/utils";

export default Vue.extend({
  name: "TrainerHeader",
  components: {
    Btn,
    HeaderUserMenu,
  },

  props: {
    finishRedirect: {
      type: Object as PropType<RawLocation>,
      required: true,
    },
  },

  data() {
    return {
      onWindowFocus: null,
      onWindowBlur: null,
      totalSeconds: 12 * 60,
    };
  },

  computed: {
    totalLessonTime(): string {
      moment.locale("pl");
      return moment.duration(this.totalSeconds, "seconds").humanize();
    },
    remainingLessonTime(): string {
      moment.locale("pl");
      return moment.duration(this.secondsRemaining, "seconds").humanize();
    },
    countTime(): boolean {
      if (this.secondsRemaining === 0) {
        this.onCountdownEnd();
      }
      return this.secondsRemaining > 0;
    },
    secondsRemaining(): number {
      const sessionPassed = this.$store.getters["timer/getSecondsPassed"];
      return this.totalSeconds - sessionPassed;
    },
    isRepetition(): boolean {
      return !!this.$route.name?.includes("repetition");
    },
    userPreferences(): UserPreferences {
      return this.$store.getters["user/preferences"];
    },
    mode(): LearningMode {
      return getLearningModeFromRoute(this.$route);
    },
    examplesInOrder(): boolean {
      return (
        this.isRepetition &&
        !this.userPreferences.randomizeRepetitionOrder &&
        [LearningMode.WRITING, LearningMode.LISTENING].includes(this.mode)
      );
    },
  },

  methods: {
    stopLearning() {
      this.$router.push(this.finishRedirect);
    },
    async changeMode() {
      if (this.isRepetition) {
        await this.$router.push({
          name: "repetitionModeSelector",
          params: { lessonId: this.$route.params.lessonId },
        });
        return;
      }
      await this.$router.push({
        name: "lessonModeSelector",
        params: { courseId: this.$route.params.courseId, lessonId: this.$route.params.lessonId },
      });
    },
    async onCountdownEnd() {
      if (this.$store.getters["timer/getFinishedAt"]) {
        return;
      }
      this.$store.commit("timer/finishSession");
      const {
        type,
        payload: { action },
      } = await this.$store.dispatch("dialog/open", {
        componentName: "lessonTimePassedDialog",
        config: {
          title: this.$t("dialogs.lessonTimePassed.title"),
        },
      });
      if (type === DialogCloseType.CLOSED || action === "continue") {
        return;
      }
      if (action === "finish") {
        await this.$router.push({ name: "dashboard" });
        return;
      }
    },
  },
  mounted() {
    this.totalSeconds = this.userPreferences.preferredLessonTime * 60;
    this.$store.commit("timer/startSession", { time: this.totalSeconds });
    Visibility.change((_, state) => {
      if (state === "hidden") {
        this.$store.commit("timer/stopSession");
      }
      if (state === "visible") {
        this.$store.commit("timer/startSession", { time: this.totalSeconds });
      }
    });
  },
  beforeDestroy() {
    this.$store.commit("timer/stopSession");
  },
});
